import React, { memo } from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Link,
} from '@chakra-ui/react'

const ModalAddSongConfirmation = memo(({ onClose, isOpen, onSubmit }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="sm">
			<ModalOverlay />

			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Heading size="lg" variant="hero">
							Wait...
						</Heading>
						<Text maxW="280px">
							Uploading a song means you have read the VidDay{' '}
							<Link
								fontWeight="bold"
								color="link"
								alt="Visit Terms of Use"
								href="https://www.vidday.com/terms-of-use"
								target="_blank">
								audio submission terms
							</Link>{' '}
							and agree to them.
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" colorScheme="gray" onClick={onClose}>
							No, Cancel
						</Button>
						<Button onClick={onSubmit}>Yes, I Agree</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalAddSongConfirmation
