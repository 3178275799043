import React from 'react'
import { HStack, Box, Container, useStyleConfig } from '@chakra-ui/react'

const NavBar = (props) => {
	const { variant, pos, ...rest } = props

	const styles = useStyleConfig('NavBar', { variant })

	// Pass the computed styles into the `__css` prop
	return (
		<Box __css={styles} pos={pos}>
			<Container maxW="container.xl" height="80px">
				<HStack
					sx={{
						alignItems: 'center',
						justifyContent: 'space-between',
						width: 'full',
						height: '80px',
						position: 'relative',
						px: ['0.5rem', '1rem'],
						spacing: '3%',
					}}
					{...rest}
				/>
			</Container>
		</Box>
	)
}

export default NavBar
