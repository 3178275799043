import React from 'react'
import { chakra, Box, Container, Heading, VStack, Text, HStack, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import BackButton from '../../atoms/nav-controls/BackButton'
import MusicButton from '../../atoms/nav-controls/MusicButton'
import ThemesButton from '../../atoms/nav-controls/ThemesButton'
import PreviewButton from '../../atoms/nav-controls/PreviewButton'
import NavBar from '../../atoms/NavBar'
import FooterBase from '../../molecules/FooterBase'
import { ThemeSelector } from '../Event/selectors/ThemeSelector'
import loadable from '@loadable/component'
import Playlist from './selectors/Playlist'
import PlaylistProvider from '../../../contexts/PlaylistProvider'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import ModalAddSong from '../../organisms/ModalAddSong'
import Divider from '../../atoms/Divider'
import { FeatureItemFullWidth } from '../../molecules/FeatureItem'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { LaunchRounded } from '../../atoms/Icon'

const ThemeCoverSelector = loadable(() => import(/* webpackPrefetch: true */ '../Event/selectors/ThemeCoverSelector'), {
	ssr: false,
})

const Controls = () => {
	const navigate = useNavigate()
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)

	return (
		<NavBar position="fixed">
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(handlers.root)} />
			</HStack>
			<HStack justifyContent="flex-end">
				<ThemesButton onClick={() => navigate(handlers.theme)} />
				<MusicButton onClick={() => navigate(handlers.music)} />
				<PreviewButton text="Make Preview" onClick={() => navigate(handlers.preview)} />
			</HStack>
		</NavBar>
	)
}

const Customize = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const { uuid } = useParams()
	const recipients = useEventRecipients()
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(uuid)
	const addSongModal = useDisclosure()

	const handleSelectSong = () => {
		addSongModal.onOpen()
		// navigate(handlers.music)
	}

	const feature = {
		path: '/assets/images/graphics/songfinch-callout',
		imgTransform: ['translate(1rem, 0)', 'none'],
		content: () => (
			<chakra.div maxW={[null, 'calc(100% - 80px)']}>
				<Text variant="title">Get a Custom Song</Text>
				<Text pt=".25rem" line-height="normal">
					Get a song created just for {recipients.firstname} that relives your favorite memories, or
					celebrates a big accomplishment.
				</Text>
			</chakra.div>
		),
		isExternal: true,
		href: 'https://songfinch.pxf.io/anZN2j',
		buttonText: (
			<>
				Discover More <LaunchRounded ml=".5rem" w="1.25rem" />
			</>
		),
		// Used for tracking
		btnProps: {
			id: 'songfinch-call-out',
		},
	}

	return (
		<>
			<Controls />

			<Container maxW="container.xl" py="1rem">
				<Box mt="1rem" mb="1.5rem" textAlign="center">
					<Heading variant="hero">Customize</Heading>
					<Text>Personalize your video with a beautiful theme and subtle background music.</Text>
				</Box>
				<VStack spacing="1rem" w="full">
					<SimpleGrid columns={[1]} spacing="1rem" w="full">
						<ThemeSelector event={event} onClick={() => navigate(handlers.theme)} />
					</SimpleGrid>

					<PlaylistProvider>
						<Playlist onAddSong={handleSelectSong} />
					</PlaylistProvider>

					<Divider text="Extra Options" variant="dashed" my="2rem" />

					<SimpleGrid w="full" spacing="1rem">
						<ThemeCoverSelector event={event} onClick={() => navigate(handlers.themeCover)} />
						<FeatureItemFullWidth color="white" item={feature} overflow="hidden" />
					</SimpleGrid>
				</VStack>
				<FooterBase />
				<ModalAddSong isOpen={addSongModal.isOpen} onClose={addSongModal.onClose} />
			</Container>
		</>
	)
}

export default Customize
