export default {
	LEFT: 37, // Arrow Left
	RIGHT: 39, // Arrow Left
	UP: 38, // Arrow Up
	DOWN: 40, // Arrow Down
	BACKSPACE: 8, // Backspace
	ESCAPE: 27, // Escape
	SPACE: 32, // Spacebar
	RETURN: 13, // Return
	CNTL: 17, // Cntl
	COMMAND: 55, // Command
	CONTROL: 59, // Control
	SHIFT: 16, // Shift
	DELETE: 68, // D
	INFO: 73, // I
	SAVE: 83, // S
}
