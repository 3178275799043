import { useEffect, useMemo, useState } from 'react'
import { automationTemplates } from '@vidday/data'
import { useAuthContext } from '../contexts/AuthProvider'

/** REVIEW HOW THIS HOOK DEFFERS FROM THE useEventTheme */
export const useAutomationTemplates = (event) => {
	const { isAdmin } = useAuthContext()

	/** Get only selectable automation templates */
	const [selectableTemplates, setSelectableTemplates] = useState([])

	useEffect(() => {
		let templates = automationTemplates.filter((t) => t.selectable)
		/** If not in beta mode (i.e. not superadmin), also filter out beta templates ** unless selected **  */
		if (!isAdmin) {
			templates = templates.filter((t) => {
				return !t.beta || t.id === event.themeTemplateId
			})
		}
		setSelectableTemplates(templates)
	}, [isAdmin, event])

	/** Is the current theme of the event still available? */
	const isThemeAvailable = useMemo(() => {
		return (
			event.themeTemplateId &&
			selectableTemplates &&
			selectableTemplates.filter((theme) => theme.id == event.themeTemplateId).length > 0
		)
	}, [selectableTemplates, event])

	/** Get current theme, and use default if not available */
	const currentTheme = useMemo(() => {
		if (selectableTemplates && selectableTemplates.length > 0) {
			return isThemeAvailable
				? selectableTemplates.filter((theme) => theme.id == event.themeTemplateId)[0]
				: selectableTemplates.filter((theme) => theme.default)[0]
		} else return automationTemplates.filter((theme) => theme.default)[0]
	}, [event, isThemeAvailable, selectableTemplates])

	const defaultTheme = useMemo(() => {
		return automationTemplates.filter((theme) => theme.default)[0]
	}, [])

	return {
		selectableTemplates,
		isThemeAvailable,
		currentTheme,
		defaultTheme,
	}
}
