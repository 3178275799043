import React, { useEffect } from 'react'

/**
 * Scroll to top on component mount
 */
const useScrollTopOnMount = () => {
	useEffect(() => {
		if (typeof window !== 'undefined' && window) {
			window.scrollTo(0, 0)
		}
	}, [])
}

export default useScrollTopOnMount
