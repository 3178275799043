import React from 'react'
import { useAutomationTemplates } from '../../../../../hooks/useAutomationTemplates'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'
import { EditRounded } from '../../../../atoms/Icon'
/**
 * Wrapper for the Theme Selector.
 * Accept a given event to generate a label based on the current theme,
 * and other props passed to the FeatureSelector.
 * @param {object} event
 */
export const ThemeSelector = ({ event, onClick }) => {
	const { currentTheme } = useAutomationTemplates(event)

	return (
		<NavCard onClick={onClick}>
			<NavCardCover path={currentTheme.thumb.slice(0, -4)} useSrcSet={false} />
			<NavCardContent title={'Video Theme'} description={currentTheme.label} />
			<NavCardActions>
				<NavCardButton>
					<EditRounded h="1.25rem" w="1.25rem" color="link" />
				</NavCardButton>
			</NavCardActions>
		</NavCard>
	)
}
