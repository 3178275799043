import React from 'react'
import { Container, Flex, Link, Image, Text, useColorModeValue } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export default (props) => {
	const textColor = useColorModeValue('inherit', '#47596A')

	return (
		<Container maxW="container.xl" mt="3rem" pt="1rem" pb="2rem" {...props}>
			<Flex direction="column" alignItems="center">
				<Link as={RouterLink} to="/" mb="0.5rem">
					<Image src="/assets/images/graphics/vidday-logo-footer.svg" />
				</Link>
				<Text fontSize="0.625rem" color={textColor}>
					© 2015-{new Date().getFullYear()} VidDay Media Inc. All Rights Reserved.
				</Text>
			</Flex>
		</Container>
	)
}
