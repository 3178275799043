import React from 'react'
import { Box, LightMode, useStyleConfig } from '@chakra-ui/react'

const Card = (props) => {
	const { variant, ...rest } = props

	const styles = useStyleConfig('Card', { variant })

	// Pass the computed styles into the `__css` prop
	return (
		<LightMode>
			<Box __css={styles} {...rest} />
		</LightMode>
	)
}

export const Receipt = (props) => {
	return (
		<>
			<Box
				sx={{
					height: '10px',
					width: '100%',
					background: "url('/assets/images/graphics/finalize-receipt-edge.svg')",
					position: 'relative',
					zIndex: props?.zIndex,
				}}
			/>
			<Card variant="square" boxShadow="lg" {...props} />
			<Box
				mb="2rem"
				sx={{
					height: '10px',
					width: '100%',
					transform: 'rotate(180deg)',
					background: "url('/assets/images/graphics/finalize-receipt-edge.svg')",
					position: 'relative',
					zIndex: props?.zIndex,
				}}
			/>
		</>
	)
}

export default Card
