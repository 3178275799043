import React from 'react'
import { Flex, Text, Divider } from '@chakra-ui/react'

const Default = ({ orientation = 'horizontal', text, ...rest }) => {
	if (text) {
		return (
			<Flex w="full" alignItems="center">
				<Divider orientation="horizontal" {...rest} />
				<Text as="strong" minW="fit-content" m="0rem 1rem" color="gray.600">
					{text}
				</Text>
				<Divider orientation="horizontal" {...rest} />
			</Flex>
		)
	} else {
		return <Divider orientation={orientation} {...rest} />
	}
}

export default Default
