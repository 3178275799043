import { useRef, useState } from 'react'

export const useEventHandlersV2 = (uuid) => {
	if (!uuid) {
		console.error(`bad uuid useEventHandlersV2: "${uuid}"`)
	}
	if (uuid === 'undefinded') {
		console.error(`bad uuid useEventHandlersV2; arg is string literal "undefined"`)
	}
	const uuidRef = useRef(uuid)

	const [root] = useState(`/event/${uuid}`)
	const [root_public] = useState(`/e/${uuid}`)
	const [root_v1] = useState(`/${uuid}`)

	const [handlers] = useState({
		/** redirect to theme selection */
		root: `${root}`,
		/** redirect to theme selection */
		checkout: `${root}/checkout`, //navigate(`${root}/checkout`),
		/** redirect to theme selection */
		theme: `${root}/customize/theme`,
		/** Redirect to the theme cover photo upload */
		themeCover: `${root}/customize/upload/logo`, //navigate(`${root}/upload`),
		/** redirect to theme selection */
		edit: `${root}/edit`,
		/** redirect to music selection */
		music: `${root}/music`,
		/** redirect to music upload (custom songs) */
		upload_song: `${root}/customize/upload/song`, //upload-song`,
		/** Money Collection page */
		fund_contribute: `${root}/collect`, //navigate(`${root}/fund/contribute`),
		/** Redirect to invitation page */
		invitation: `${root}/invitation`,
		/** THIS ONE SHOULD BE UPDATED FOR V2 */
		invitation_list: `${root}/invitation/list`,
		/** Redirect to broadcast page */
		contributor_list: `${root}/contributor-list`,
		/** Redirect to broadcast page */
		broadcast: `${root}/broadcast`,
		/** Redirect to customize page */
		customize: `${root}/customize`,
		/** Redirect to finalize page */
		finalize: `${root}/finalize`,
		/** Redirect to preview page */
		preview: `${root}/preview`,
		/** Redirect to the cover photo upload */
		upload_cover: `${root}/cover/invitation`, //navigate(`${root}/upload`),
		/** Redirect to the upload page */
		upload: `${root}/upload`, //navigate(`${root}/upload`),
		/** Redirect to the upload text card page */
		upload_card: `${root}/card`, // navigate(`${root_v1}/upload`), // navigate(`${root}/card`),
		/** Redirect to the upload Gif page */
		upload_gif: `${root}/upload-gif`, //navigate(`${root}/upload-gif`),
		/** Redirect to the upload Unsplash page */
		upload_unsplash: `${root}/upload-gallery`, //navigate(`${root}/upload-gallery`),
		/** Redirect to page to share event with recipient only */
		share_recipient: `${root}/send/recipient`, //navigate(`${root}/share/recipient`),
		share_general: `${root}/send/general`,

		download: `${root}/download`,
		keepsake: `${root}/keepsake`,
		keepsake_usb: `${root}/keepsake/usb`,
		keepsake_dvd: `${root}/keepsake/dvd`,
		keepsake_video_book: `${root}/keepsake/video-book`,
		add_ons: `${root}/add-ons`,

		public_root: `${root_public}`,
		public_redeem: `${root_public}/redeem`,
		public_keepsake: `${root_public}/keepsake`,
		public_keepsake_usb: `${root_public}/keepsake/usb`,
		public_keepsake_dvd: `${root_public}/keepsake/dvd`,
		public_keepsake_video_book: `${root_public}/keepsake/video-book`,
		public_invite: `${root_public}/invite`,
		public_contribute: `${root_public}/contribute`,
		public_contribute_form: `${root_public}/submit`,
		public_upload_thankyou: `${root_public}/upload-thankyou`,
		public_add_ons: `${root_public}/add-ons`,
		public_rsvp: `${root_public}/rsvp`,
		public_rsvp_success: `${root_public}/rsvp/success`,
		public_download: `${root_public}/download`,
	})

	// useEffect(() => {
	// 	if(uuidRef.current !== uuid){

	// 	}
	// }, [uuidRef.current])
	return handlers
}
/**
 * Manage all redirect handlers of an event given its uuid
 * @param {string} uuid
 * @returns
 */
