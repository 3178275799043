import React, { memo } from 'react'
import Card from '../../atoms/Card'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
} from '@chakra-ui/react'
import { UploadRounded, QueueMusicRounded } from '../../atoms/Icon'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useParams } from 'react-router'
import { history } from '../../../../history'
import useImageGenerator from '../../../hooks/useImageGenerator'
import ModalAddSongConfirmation from '../ModalAddSongConfirmation'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow'

const itemCardStyles = {
	w: 'full',
	p: '1rem',
	boxShadow: 'base',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	flexDirection: 'row',
	_hover: { cursor: 'pointer' },
}

const buttonStyles = {
	size: 'md',
	mr: '1rem',
	_hover: { cursor: 'pointer' },
}

const ItemCard = memo(({ icon, label, onClick }) => {
	return (
		<Card onClick={onClick} sx={itemCardStyles}>
			<Button as="div" aria-label={label} variant="iconCta" sx={buttonStyles}>
				{icon}
			</Button>
			<Text color="#0066CC" fontWeight="600" _hover={{ cursor: 'pointer' }}>
				{label}
			</Text>
		</Card>
	)
})

const ModalAddSong = memo(({ onClose, isOpen }) => {
	const { uuid } = useParams()
	const handlers = useEventHandlersV2(uuid)

	const img = useImageGenerator({
		path: '/assets/images/graphics/vidday-music-notes',
		ext: 'png',
	})

	const confirmationModal = useDisclosure()

	const handleUploadConfirmation = () => {
		confirmationModal.onOpen()
	}

	const handleUploadSong = () => {
		redirectToNextIfWindow(`/event/${uuid}/customize/upload/song`)
	} //history.push(handlers.upload_song)

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="sm">
			<ModalOverlay />

			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="Upload songs" />

						<Heading size="lg" variant="hero">
							Add a song.
						</Heading>

						<Text maxW="280px">Choose from our curated library or upload your own song.</Text>

						<ItemCard
							label="Browse our Library"
							onClick={
								() => {
									redirectToNextIfWindow(handlers.music)
								}
								// history.push(handlers.music)
							}
							icon={<QueueMusicRounded color="link" />}
						/>
						<ItemCard
							label="Upload your own"
							onClick={handleUploadConfirmation}
							icon={<UploadRounded color="link" />}
						/>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" colorScheme="gray" onClick={onClose}>
							Cancel
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
			<ModalAddSongConfirmation
				isOpen={confirmationModal.isOpen}
				onClose={confirmationModal.onClose}
				onSubmit={handleUploadSong}
			/>
		</Modal>
	)
})

export default ModalAddSong
