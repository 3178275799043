import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEventContext } from './EventProvider'
import { musicLibrary } from '@vidday/data'
import { updateEvent } from '../../api/app/events'
import { fetchTracks } from '../../api/app/media'
import { useAuthContext } from './AuthProvider'

/** Build Current Event Context */
const PlaylistContext = createContext()

/** Retrieve all selectable songs */
let songsV1 = musicLibrary ? musicLibrary.filter((track) => track?.selectable) : []
// All selectable tracks are sorted by genre
songsV1 = songsV1.sort((a, b) => (a.genre > b.genre ? 1 : a.genre < b.genre ? -1 : 0))

/** Get song objects */
const getSelectedTracks = (collection, ids) => {
	let results = []
	ids.forEach((id) => {
		const element = collection.find((el) => el.id == id)
		if (element) results.push(element)
	})
	return results
}

/** Define Current Event Context Provider  */
const PlaylistProvider = ({ children }) => {
	const dispatch = useDispatch()

	const songsFromServer = useSelector((s) => s.entities.tracks.entities, shallowEqual)

	const { event } = useEventContext()
	const { isAdmin } = useAuthContext()

	/** Fetch Existing and linked events */
	useEffect(() => {
		if (songsFromServer?.length <= 0) {
			dispatch(fetchTracks())
		}
	}, [])

	/** Store all available tracks */
	const [tracks, setTracks] = useState([])
	/** Store a reference of the Current Tracks */
	const [currentTracks, setCurrentTracks] = useState(null)
	/** Store a reference of the Current Tracks (those saved on the event model) */
	const [trackIds, setTrackIds] = useState(event?.backgroundMusicIds)
	/** Store a reference of the track that is currently playing */
	const [playingTrackId, setPlayingTrackId] = useState(null)
	/** Store a reference of the position of the track in the playlist, when it's being played. */
	const [playingTrackKey, setPlayingTrackKey] = useState(null)
	/** Ref to the event's backgroundMusic */
	const hasBackgroundMusic = event.backgroundMusicIds?.length > 0

	const [trackDurations, setTrackDurations] = useState([])

	const [duration, setDuration] = useState(0)

	/** Only retrieve the currently selected tracks */
	const [selectedTracks, setSelectedTracks] = useState()

	useEffect(() => {
		if (tracks && tracks.length > 0) {
			setSelectedTracks(getSelectedTracks(tracks, trackIds))
		}
	}, [trackIds, tracks])

	/**
	 * Retrieve current track
	 */
	useEffect(() => {
		// Important to only make edits when they are different only
		const t =
			event.backgroundMusicIds && event.backgroundMusicIds.length > 1
				? musicLibrary.filter(
						(track) => event.backgroundMusicIds && event.backgroundMusicIds.includes(track.id)
				  )[0]
				: musicLibrary.filter((track) => track.default)[0]
		setCurrentTracks(event.backgroundMusicIds || [])
		setTrackIds(event.backgroundMusicIds || [])
	}, [event.backgroundMusicIds])

	/**
	 * Build filtered collection of tracks,
	 * display all songs for admins
	 */
	useEffect(() => {
		// Retrieve all selectable songs
		let songs = songsFromServer ? songsFromServer?.filter((track) => track?.selectable) : [] //musicLibrary ? musicLibrary.filter((track) => track.selectable) : []
		// All selectable tracks are sorted by genre
		songs = songs.sort((a, b) => (a.genre > b.genre ? 1 : a.genre < b.genre ? -1 : 0))
		// Then filter out beta version if not admin
		if (!isAdmin) {
			songs = songs.filter((track) => !track.beta)
		}
		setTracks(songs)
	}, [songsFromServer, isAdmin])

	const [hasCustomSong, setHasCustomSong] = useState(false)

	useMemo(() => {
		let custom = selectedTracks ? selectedTracks.filter((t) => t.genre == 'Custom') : []
		setHasCustomSong(custom.length > 0)
	}, [selectedTracks])

	/**
	 * Handler to update the background music boolean on the event
	 */
	const setBackgroundMusic = (val) => {
		dispatch(
			updateEvent({
				id: event.id,
				backgroundMusic: val,
			})
		)
	}

	/**
	 * Handler to save the track id as the new track on the event model
	 */
	const savePlaylist = (selection) => {
		if (JSON.stringify(event.backgroundMusicIds) !== JSON.stringify(selection) || !event.backgroundMusic) {
			// Also update internal state for quick UI
			setTrackIds(selection)

			dispatch(
				updateEvent({
					id: event.id,
					backgroundMusicIds: selection,
				})
			)
		}
	}

	/**
	 * Handler to update the value of the track being played. Accepts an id, or null/false
	 */
	const playTrack = (trackId, key) => {
		setPlayingTrackId(trackId)
		setPlayingTrackKey(key)
	}

	/**
	 * Record the duration of a track, obj of id/duration key
	 * @param {*} obj
	 */
	const recordTrackDuration = (obj) => {
		const itemIndex = trackDurations.findIndex((el) => el.id === obj.id)
		if (itemIndex !== -1) {
			// Create clone of original durations
			let newDurations = [...trackDurations]
			// Update info corresponding to the current object
			newDurations[itemIndex] = obj

			setTrackDurations([...newDurations])
		} else {
			setTrackDurations([...trackDurations, obj])
		}
	}

	const state = {
		tracks,
		currentTracks,
		hasCustomSong,
		selectedTracks,
		trackIds,
		playingTrackId,
		playingTrackKey,
		hasBackgroundMusic,
		setBackgroundMusic,
		playTrack,
		savePlaylist,
		trackDurations,
		recordTrackDuration,
		duration,
	}

	return <PlaylistContext.Provider value={state}>{children}</PlaylistContext.Provider>
}

export default PlaylistProvider

export const usePlaylistContext = () => useContext(PlaylistContext)
