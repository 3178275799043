import React from 'react'
import { Button } from '@chakra-ui/react'
import { TheatersRounded } from '../Icon'

const PreviewButton = ({ onClick, text, ...rest }) => {
	return (
		<Button
			variant="control"
			px="0.5rem"
			onClick={onClick}
			// Overwrite width only for this button
			minWidth="100px"
			w="100px"
			{...rest}>
			<TheatersRounded />
			{text || 'New Preview'}
		</Button>
	)
}

export default PreviewButton
