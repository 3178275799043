import React from 'react'
import { Button, Icon } from '@chakra-ui/react'

import keys from '../NavBar/keys'
import { ArrowBackRounded } from '../Icon'
/** Hooks */
import useEventListener from '../../../hooks/useEventListener'

// Bind KeyBoard events with Button hotKey listener
const handleKeyDown = (event, onClick, hotKey) => {
	// Prevent default for hot keys
	if (event.which == hotKey) {
		event.preventDefault()
	}
	// Button onClick
	if (event.which == hotKey) {
		onClick()
	}
}

const BackButton = ({ onClick, ...rest }) => {
	useEventListener('keydown', (e) => handleKeyDown(e, onClick, keys.ESCAPE))
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<ArrowBackRounded />
			Back
		</Button>
	)
}

export default BackButton
